<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="users.length">
    <div class="con-input-search vs-table--search mt-5">
            <input
              type="text"
              placeholder="بحث عن طريق الاسم"
              class="input-search vs-table--search-input ml-2 p-5 font-cairo"
              v-model="search"
              @blur="handleSearch()"
            />
            <i class="vs-icon notranslate icon-scale material-icons  null"
              >search</i
            >
          </div>
      <vs-table ref="table" :data="users">
        <template slot="thead">
          <vs-th sort-key="username">الإسم</vs-th>
          <vs-th sort-key="phone">رقم الهاتف</vs-th>
          <vs-th sort-key="address">العنوان</vs-th>
          <vs-th>العمليات</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="user" v-for="(user, index) in data" :key="index">
              <vs-td>
                <p class="product-name font-medium truncate">{{ user.name }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ user.phone }}</p>
              </vs-td>

              <vs-td>
                <vs-button
                  size="small"
                  type="border"
                  transparent
                  class="product-order-status"
                  @click="userAddress(user.address)"
                >
                  عناوين المستخدم</vs-button
                >
              </vs-td>

              <vs-td>
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="w-5 h-5 hover:text-success stroke-current"
                  class="ml-2"
                  title="ارشفة"
                  @click="confirm(user.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-4"
        :total="lastPage"
        v-model="current_Page"
        @change="fetchEndUsers(current_Page)"
      ></vs-pagination>
    </div>

    <EmptyState page="المستخدمين" :display="false" v-else></EmptyState>

    <vs-popup
      class="holamundo"
      title="عناوين المستخدم"
      :active.sync="popupActive"
    >
      <div v-for="(address, index) in addresses" :key="index">
        <span> {{ address.title }}</span>
        <p v-if="address.city">
          {{ address.city.name }} - {{ address.description }}
        </p>
        <br />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import EmptyState from "@/views/emptyState.vue";
import { mapActions } from "vuex";
export default {
  components: {
    EmptyState,
  },
  data() {
    return {
      isMounted: false,
      current_Page: 1,
      popupActive: false,
      addresses: [],
      user_id: "",
      display:false,
      search:''
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.users.length;
    },
    users() {
      return this.$store.state.users.users;
    },
    lastPage() {
      return this.$store.state.users.pagination;
    },
  },
  methods: {
    ...mapActions("users", ["fetchEndUsers", "deleteUser"]),
    userAddress(address) {
      this.popupActive = true;
      this.addresses = address;
    },
    confirm(id) {
      this.user_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من ارشفة هذا المستخدم؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.archiveUser,
      });
    },
    archiveUser() {
      this.deleteUser(this.user_id)
        .then((response) => {
          this.successDialog(response.data.message);
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
    handleSearch(){
      this.fetchEndUsers(`filter[name]=${this.search}`)
    }
  },
  created() {
    let params = `page=${this.current_Page}`
    this.fetchEndUsers(params);
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>